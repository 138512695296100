import { OrderType } from '@/enums';
import { cartStorageKey } from '@/keys';
import {Order, OrderItem, OrderChoice, Cart, CartItem, CartState, ApplicationSettings} from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import axios from "axios";

export const CartStore = defineStore(cartStorageKey, () => {
    /* State */
    const state = ref<CartState>({
        shopId: null,
        showOrderInfo: false,
        showAddItemButton: false,
        hasSelectedSuggestions: false,
        visible: false,
        disabled: false,
        orderType: OrderType.DineInPlace,
        items: [],
        editMode: false,
        currentOrderChanged: false,
        note: null,
        spotId:null,
        settings: { orderTypes : [], enableCustomerLogin:false, externalLoginProviders:[], version: "0.0.0.0" },
        initiated: false
    });

    const loadFromStorage = (e: StorageEvent | undefined | null) => {
        //If event was thrown by irrelevant storage
        if (e && e.key !== cartStorageKey) {
            return;
        }

        const cartJson: string | null = localStorage.getItem(cartStorageKey);
        axios.get<ApplicationSettings>('/api/settings')
            .then((response) => response.data)
            .then((data:ApplicationSettings) => {
                
                state.value.initiated = true;
                setSettings(data);
                save();
            });
        
        if (cartJson) {
            try {
                const cart = JSON.parse(cartJson) as Cart;
                state.value.items = cart.items;
                state.value.orderType = cart.orderType;
                state.value.shopId = cart.shopId;
                state.value.shopName = cart.shopName;
                state.value.note = cart.note ?? null;
                state.value.spotId = cart.spotId;
                state.value.settings = cart.settings;
            }
            catch (ex) {
                console.log(ex);
                //If json is corrupt, remove data.
                localStorage.removeItem(cartStorageKey);
            }
        }else {
            console.log('no cart json')
        }
    }

    /* Getters - computed */
     const totalPrice = computed<number>(() => {

        let total = 0;

        if (state.value.items?.length) {
            state.value.items.forEach(x => {

                if (x.article && x.article.price) {
                    total += x.article.price * x.quantity;
                }

                if (x.flatChoices) {
                    x.flatChoices.forEach(y => {
                        if (y.article && y.article.price) {
                            total += y.article.price * x.quantity;
                        }
                    });
                }
            });
        }

        return total;
    });



    /* Actions */

    /* --- Getters --- */
    const get = (id: string) => {
        return state.value.items.find(item => item.id === id);
    }

    const getItem = (id: string): CartItem | undefined => {
        return state.value.items.find(x => x.id === id);
    }


    const save = () => {
        const obj = {
            items: state.value.items ?? [],
            orderType: state.value.orderType,
            shopId: state.value.shopId,
            shopName: state.value.shopName,
            note: state.value.note,
            settings: state.value.settings,
            spotId: state.value.spotId
        } as Cart;

        localStorage.setItem(cartStorageKey, JSON.stringify(obj));
    }


    /* --- Setters --- */
    const add = (item: CartItem | null, autoSave = true) => {

        if (!item)
            return;

        if (item.quantity === 0) {
            remove(item);
            return;
        }

        //find item with same id, if so update 
        let index = state.value.items.findIndex(x => x.id === item.id);

        if (index > -1) {
            state.value.items[index] = item;
             autoSave && save();
            return;
        }
        else if (!item.flatChoices.length) {
            //find item with same Article.code and no Choices, if so add to same order
            index = state.value.items.findIndex(x => x.article.code === item.article.code && x.flatChoices.length === 0);

            if (index > -1) {
                state.value.items[index].quantity += item.quantity;
                autoSave && save();
                return;
            }
        } else {
            //find item with same Article.code and same Choices, if so add to same order
            const index = state.value.items.findIndex(x => {
                return x.article.code === item.article.code && x.flatChoices.length === item.flatChoices.length && JSON.stringify(x.flatChoices) === JSON.stringify(item.flatChoices);
            });

            if (index > -1) {
                state.value.items[index].quantity += item.quantity;
                autoSave && save();
                return;
            }
        }

        // If no matching order exist then add as new cart item.
        state.value.items.push(item);
        autoSave && save();
    } 

    const increase = (item: CartItem) => {
        const index = state.value.items.findIndex(x => x.id === item.id);

        if (index > -1) {
            state.value.items[index].quantity += 1;
            save();
        }
    }

    const decrease = (item: CartItem) => {
        if (item.quantity === 1) {
            remove(item);
        }

        const index = state.value.items.findIndex(x => x.id === item.id);

        if (index > -1) {
            state.value.items[index].quantity -= 1;
            save();
        }
    }

    const remove = (item: CartItem) => {
        const index = state.value.items.indexOf(item);

        if (index > -1) {
            state.value.items.splice(index, 1);
            save();
        }
    }

    const clear = (clearStore: boolean | undefined) => {
        state.value.items = [];

        if (clearStore) {
            state.value.shopId = null;
        }
        //state.value.note = null;
        localStorage.removeItem(cartStorageKey);
        save();
    }

    const setShop = (shopId: number | null, shopName: string) => {
        state.value.shopId = shopId;
        state.value.shopName = shopName;
        save();
    }

    const setOrderType = (orderType: OrderType) => {
        state.value.orderType = orderType;
        save();
    }
    const getOrderType = (): OrderType => {
        return state.value.orderType;
    }
    
    const setSettings = (settings:ApplicationSettings)=>{
        state.value.settings = settings;
        save();
    }
    
    const setNote = (note:string|null)=>{
        state.value.note = note;
        save();
    }

    const setDeliverySpot = (spotId:number|null)=>{
        state.value.spotId = spotId;
        save();
    }

    const compareShop = (shopId: number | null): boolean => {

        if (state.value.shopId !== null) {
            return shopId !== undefined && shopId === state.value.shopId;
        }

        return true;
    }

    const convertToOrderRequest = (customerCode: string | null | undefined): Order => {
        const items: OrderItem[] = state.value.items.map(item => {
            const allItemChoices = [];
            for (const prop in item.choices) {
                const choices = item.choices[prop];
                const levelChoices: OrderChoice[] = choices.map(c => {
                    return {
                        level: Number(prop),
                        articleCode: c.article.code,
                        choiceCode: c.code.trim()
                    };
                })
                allItemChoices.push(...levelChoices);
            }

            return {
                code: item.article.code,
                quantity: item.quantity,
                choices: allItemChoices             
            }
        });

        return {
            //sessionId: state.sessionId,
            shopId: state.value.shopId,
            orderType: state.value.orderType,
            pickupTime: new Date(),
            items: items,
            customerCode: customerCode,
            note: state.value.note
        } as Order;
    };


    loadFromStorage(null);
    window.addEventListener('storage', loadFromStorage);

    return {
        clear,
        add,
        get,
        increase,
        decrease,
        remove,
        getItem,
        convertToOrderRequest,
        totalPrice,
        compareShop,
        setOrderType,
        getOrderType,
        //setSettings,
        //loadFromStorage,
        setShop,
        setNote,
        setDeliverySpot,
        state,
        save
    }
})